export default {
  demografi: {
    nama_pasien: {
      label: 'Nama Pasien',
      widget: 'wtext',
      data: null,
      disabled: true,

      col: 3
    },
    no_rekam_medis: {
      label: 'No Rekam Medis',
      widget: 'wtext',
      disabled: true,

      data: null,
      col: 3
    },
    tanggal_lahir: {
      label: 'Tangga Lahir',
      widget: 'wdate',
      data: null,
      col: 3
    },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      disabled: true,

      data: [],
      col: 3
    },
    ruangan: {
      widget: 'wtext',
      label: 'Dokter',
      disabled: true,

      data: null,
      col: 3
    },
    kamar_kelas: {
      widget: 'wtext',
      data: null,
      disabled: true,

      label: 'Jenis Rawatan',
      col: 3
    },
    jenis_kelamin: {
      widget: 'wradio',
      data: [
        { text: 'Laki-Laki', value: 'laki-laki' },
        { text: 'Wanita', value: 'wanita' }
      ],
      col: 6
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      col: 4,
      disabled: true,

      label: 'No Folio'
    },
    sub_unit_id: {
      widget: 'wtext',
      data: null,
      disabled: true,

      col: 4,
      label: 'Sub Unit Dokter'
    },
    reg_id: {
      widget: 'wtext',
      data: null,
      disabled: true,

      label: 'No Reg',
      col: 4
    }
  },
  form: {
    l1: {
      label: 'Tanggal Periksa',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    p1: {
      label: ' ',
      widget: 'wtext',
      data: '',
      disabled: true,
      col: 9
    },
    l2: {
      label: 'Dokter Pemeriksa',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    p2: {
      label: ' ',
      widget: 'wtext',
      data: [],
      disabled: true,
      col: 9
    },
    l3: {
      label: 'EKG Pretest',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    p3: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 9
    },
    l4: {
      label: 'Treadmill Test',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    p4: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 9
    },
    l5: {
      label: 'Protokol',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    p5: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 9
    },
    l6: {
      label: 'Lama Latihan',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    p6: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 3
    },
    l7: {
      label: 'Stage',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    p7: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 4
    },
    l8: {
      label: 'Indikasi Berhenti',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    p8: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 9
    },
    l9: {
      label: 'Heart Rate Tercapai',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    p9: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 3,
      property: { attrs: { prefix: 'bpm : ', type: 'string' } }

    },
    l10: {
      label: 'Dugaan Maksimal',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    p10: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 3,
      property: { attrs: { prefix: 'bpm : ', type: 'string' } }

    },
    l11: {
      label: 'Tekanan Darah Awal',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    p11: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 3,
      property: { attrs: { prefix: 'mm/Hg : ', type: 'string' } }

    },
    l12: {
      label: 'Tertinggi',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    p12: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 3,
      property: { attrs: { prefix: 'mm/Hg : ', type: 'string' } }

    },
    l13: {
      label: 'Respon Aritmia',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    p13: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 3
      // property: { attrs: { prefix: 'mm/Hg : ', type: 'string' } }

    },
    l14: {
      label: 'Respon Iskemik',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    p14: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 3
      // property: { attrs: { prefix: 'mm/Hg : ', type: 'string' } }

    },
    l15: {
      label: 'Hasil Pemeriksaan',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    p15: {
      label: ' ',
      widget: 'wtextarea',
      data: [],
      col: 9
      // property: { attrs: { prefix: 'mm/Hg : ', type: 'string' } }

    },
    l16: {
      label: 'Hasil Pemeriksaan',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    p16: {
      label: ' ',
      widget: 'wtextarea',
      data: [],
      col: 9
      // property: { attrs: { prefix: 'mm/Hg : ', type: 'string' } }

    }

  }
}
