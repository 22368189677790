<template>
  <div class="m-4">
    <h3 class="m-1 mt-5 mb-3">Treadmill</h3>
    <v-row>
      <v-col cols="12" md="8" sm="8" lg="8">
        <v-card color="teal darken-1" dark>
          <v-card-text>
            <v-row class="mt-1 mb-0">
              <v-col
                v-for="(v, i) in master.demografi"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-1 mb-1 pt-2 pb-1"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.demografi[i] = e)"
                  :value="data.demografi[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :disabled="v.disabled ? v.disabled : false"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="mt-1">
          <v-card-text>
            <v-row>
              <v-col
                v-for="(v, i) in master.form"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-2 pb-2"
                :key="i"
              >
                <!-- {{ v.data }} -->
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.form[i] = e)"
                  :value="data.form[i]"
                  vclass="c-text-field"
                  :property="v.property"
                  :data="v.data"
                  :disabled="v.disabled ? v.disabled : false"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="4" sm="4" lg="4">
        <v-card>
          <v-card-title> List Treadmill </v-card-title>
          <v-card-text>
            <v-list three-line>
              <v-list-item v-for="(item, index) of listAsesmen" :key="index">
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-doctor </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.dokter }}</v-list-item-title>
                  <v-list-item-subtitle
                    ><b>Lama Latihan : </b
                    >{{ item.lama_latihan }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle
                    ><b>Heart Rate Tercapai : </b
                    >{{ item.heart_rate_tercapai }} bpm</v-list-item-subtitle
                  >
                  <v-list-item-subtitle
                    ><b>Tekanan Darah Awal : </b
                    >{{ item.tekanan_darah_awal }} mm/Hg</v-list-item-subtitle
                  >
                  <v-list-item-subtitle
                    ><b>Tertinggi : </b
                    >{{ item.tertinggi }} mm/Hg</v-list-item-subtitle
                  >
                  <v-divider></v-divider>
                  <v-list-item-action>
                    <v-btn
                      @click="viewhasil(item)"
                      color="indigo"
                      class="btn btn-block"
                      medium
                      dark
                      >Liat Hasil
                    </v-btn>
                  </v-list-item-action>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <div class="form-group">
      <div
        style="z-index: 999999"
        class="float-group btn-group-vertical"
        role="group"
        aria-label="Vertical button group"
      >
        <v-btn
          @click="postSave"
          :disabled="disablebuttonSimpan"
          :color="color"
          medium
          class="btn-block btn-simpan"
        >
          Simpan Hasil Treadmill
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import Master from '@/data/Treadmill'
import Dialog from '@/components/Dialog'
import SmartWidget from '@/components/SmartWidget.vue'

import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  // eslint-disable-next-line no-unused-vars
  setBtnLoading,
  // eslint-disable-next-line no-unused-vars
  resetBtnLoading
  , baseUrlEmr
} from '../../plugins/supports'
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    DialogConfirm: Dialog,
    // eslint-disable-next-line vue/no-unused-components
    SmartWidget
  },
  data () {
    return {
      procedurs: [
        {
          previous: '',
          expiration: ''
        }
      ],
      pegawai: '',
      itemdetail: '',
      disablebuttonSimpan: false,
      color: 'primary',
      listAsesmen: '',
      showDialog: false,
      master: Master,
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          cara_bayar: '',
          ruangan: '',
          kamar_kelas: '',
          jenis_kelamin: '',
          folio_id: '',
          sub_unit_id: '',
          reg_id: ''
        },
        form: {
          p1: '',
          p2: '',
          p3: '',
          p4: '',
          p5: '',
          p6: '',
          p7: '',
          p8: '',
          p9: '',
          p10: '',
          p11: '',
          p12: '',
          p13: '',
          p14: '',
          p15: '',
          p16: ''
        }
      },
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        }
      }
    }
  },
  created () {
    if (this.$route.query.no_rm != null) {
      this.getDataPasien(this.$route.query.folio_id)
      this.pegawai = JSON.parse(localStorage.getItem('user'))
      setTimeout(() => {
        this.getAssemen()
        this.getListAsesmen()
      }, 1000)
    }
  },
  methods: {
    postSave () {
      // eslint-disable-next-line no-unused-vars
      const data = {
        noRm: this.$route.query.no_rm,
        noDaftar: this.$route.query.registration_id,
        noFolio: this.$route.query.folio_id,
        subUnitId: this.$route.query.sub_unit_id,
        akun: this.pegawai.employee_id,
        data: this.data,
        form: this.data.form
      }

      // eslint-disable-next-line no-undef
      $.post(
        baseUrlV2 + 'rme/simpan-hasil-treadmill',
        data,
        (res) => {
          const { con, msg, results } = res
          if (con) {
            successMsg(msg)

            setTimeout(() => {
              this.getListAsesmen()
            }, 1000)
          } else {
            errorMsg(msg + results)
          }
        },
        'JSON'
      )

      console.log(data)
    },
    getDataPasien (folioId) {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-detail-pasien',
        { folio_id: folioId },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data.demografi = results
            this.data.demografi.jenis_kelamin = {
              value: results.jenis_kelamin,
              text: results.jenis_kelamin
            }
          } else {
          }
        },
        'JSON'
      )
    },
    viewhasil (params) {
      window
        .open(
          baseUrlEmr + `laporan-medis/cetak-treadmill-medis?folio_id=${params.no_folio}&no_rm=${params.no_rekam_medik}`,
          '_blank'
        )
        .focus()
    },

    getAssemen () {
      // eslint-disable-next-line no-unused-vars
      var data = {
        no_rm: this.$route.query.no_rm,
        folio_id: this.$route.query.folio_id,
        type: 'ONE'
      }

      // console.log(data)
      // eslint-disable-next-line no-undef
      $.get(
        baseUrlV2 + 'rme/get-hasil-treadmill',
        data,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data.form = results
          } else {
            this.setNilaiNormal()
          }
        },
        'JSON'
      )
    },

    getListAsesmen () {
      // eslint-disable-next-line no-unused-vars
      var data = {
        no_rm: this.$route.query.no_rm,
        folio_id: this.$route.query.folio_id,
        type: 'LIST'
      }

      // console.log(data)
      // eslint-disable-next-line no-undef
      $.get(
        baseUrlV2 + 'rme/get-hasil-treadmill',
        data,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            // this.data.form = results
            this.listAsesmen = results
          } else {
            // this.setNilaiNormal()
          }
        },
        'JSON'
      )
    },
    setNilaiNormal () {
      var today = new Date()
      var dd = String(today.getDate()).padStart(2, '0')
      var mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
      var yyyy = today.getFullYear()

      // today = mm + '/' + dd + '/' + yyyy;
      today = yyyy + '-' + mm + '-' + dd

      console.log(today)
      this.data.form.p1 = today
      this.data.form.p2 = this.data.demografi.ruangan
      this.data.form.p3 = 'Normal'
      this.data.form.p4 = 'MCU'
      this.data.form.p5 = 'Bruce'
      this.data.form.p6 = '6 menit # detik'
      this.data.form.p7 = '3'
      this.data.form.p8 = '85 % Heart Rate tercapai'
      this.data.form.p9 = '128'
      this.data.form.p10 = '178'
      this.data.form.p11 = '110/80'
      this.data.form.p12 = '140/90'
      this.data.form.p13 = '-'
      this.data.form.p14 = '-'
      this.data.form.p15 =
        'Kelas Fungsional : I\nKapasitas Kerja Fisik : 7,65 Mets\nKlasifikasi kebugaran : AVERAGE\nHasil : Inadequate Stress Test'
      this.data.form.p16 = 'Olah raga teratur Diet Seimbang Pola Hidup Sehat'
      // console.log()
      // this.data.form.p1.data = today
    }
  }
}
</script>

<style>
.float-group {
  position: fixed;
  bottom: 100px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.float {
  position: fixed;
  bottom: 160px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}
</style>
